// Gatsby requirements
import React, { useEffect } from "react"
import { graphql } from "gatsby"

// Components
import Layout from '../components/Layout/Layout'
import Seo from '../components/Layout/Seo'
import RichText from '../components/RichTextRenderer'
import Hero from '../components/Hero'
import Share from '../components/Share'
import SectionFeaturedArticles from '../components/SectionFeaturedArticles'
import SectionCards from '../components/SectionCards'
import SectionContactUs from '../components/SectionContactUs'

// Styles
import * as CSS from './Article.module.scss'


const ArticleTemplate = ({ data }) => {

    const article = data.contentfulArticle;

    useEffect(() => {
        // Add ID to each H2 and H3
        var els = document.body.querySelectorAll("h2, h3");
        for (var i = 0; i < els.length; i++) {
            const textToId = [els[i].innerText.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')]
            els[i].setAttribute('id', textToId);
        };
      }, []);

    return (
        <Layout>

            <Seo
                title={article?.articleTitle}
                metaTitle={article?.articleTitle}
                description={article?.metaDescription}
                thumbnail={
                    article?.thumbnailImage?.file?.url && 
                    `https:${article?.thumbnailImage?.file?.url}?w=900&h=900&q=80`
                }
                type="article"
                url={`/articles/${article.url}/`}
            />

            <main id="content">

                <article>

                    <Hero 
                        title={article?.heroTitle}
                        image={article?.thumbnailImage}
                        url={`articles/${article.url}/`}
                        date={article?.createdAt}
                        timeToRead={article?.fields?.timeToRead}
                    />

                    {/* Socials located on the sidebar */}
                    {/* <aside aria-label="our social media" className={CSS.aside}>
                        <a href="/"><img src="/icons/facebook.svg" alt="Visit our Facebook"/></a>
                        <a href="/"><img src="/icons/linkedin.svg" alt="Visit our LinkedIn"/></a>
                        <a href="/"><img src="/icons/twitter.svg" alt="Visit our Twitter"/></a>
                    </aside> */}

                    {/* This is where the article text is */}
                    <div className={CSS.article}>
                        {article?.articleContent &&
                            <RichText content={article?.articleContent} />
                        }
                    </div>
                    <div className={CSS.pageFooter}>
                        <a className={CSS.scrollUp} href="#content">Scroll up</a>
                        {/* <Share url={`articles/${article.url}/`}/> */}
                    </div>

                </article>

                {/* If article has blocks after the rich text, display them here */}
                {article?.blocksAfterArticle &&
                    <aside className={CSS.blocksAfterContent} aria-label="Featured content after article">
                        {article?.blocksAfterArticle?.map((block, i) => {
                            switch (block.__typename) {

                                case "ContentfulSectionFeaturedArticles":
                                    return <SectionFeaturedArticles key={i} data={block} />
    
                                case "ContentfulSectionCards":
                                    return <SectionCards key={i} data={block} /> 

                                case "ContentfulSectionContactUs":
                                    return <SectionContactUs key={i} data={block} /> 
                                    
                                default:
                                    return false
                            }
                        })}
                    </aside>
                }
            </main>

        </Layout>
    )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulArticle(url: { eq: $slug }) {
        id
        contentful_id

        articleTitle
        metaDescription {
            metaDescription
        }
        createdAt(formatString: "MMM D, YYYY")
        url
        heroTitle {
            raw
        }

        thumbnailImage {
            gatsbyImageData
            file {
              url
            }
            description
        }

        articleContent {
          raw
          references {
            __typename
            ...FragmentRichTextAsset
            ...FragmentRichTextButton
            ...FragmentRichTextArticle
            ...FragmentRichTextPage
          }
        }

        fields {
            timeToRead
        }

        blocksAfterArticle {
            __typename
            ...FragmentSectionText
            ...FragmentSectionFeaturedArticles
            ...FragmentSectionCards
            ...FragmentSectionContactUs
        }
        
    }
  }
`

export default ArticleTemplate